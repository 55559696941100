import { createSettingsParams } from '@wix/tpa-settings';
import timetableSettingsParams from '../WeeklyTimetable/settingsParams';
import { LayoutOptions } from '../../types/types';
import { ISettingsParams } from '../BookingCalendar/settingsParams';

type DailyAgendaSettingsOverride = Pick<
  ISettingsParams,
  'calendarLayout' | 'maxTimeSlotsDisplayedPerDay' | 'limitTimeSlotsDisplay'
>;

const dailyAgendaSettingsParams =
  createSettingsParams<DailyAgendaSettingsOverride>({
    calendarLayout: {
      getDefaultValue: () => LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER,
    },
    maxTimeSlotsDisplayedPerDay: {
      getDefaultValue: ({ isMobile, experiments }) => (isMobile ? 1 : 6),
    },
    limitTimeSlotsDisplay: {
      getDefaultValue: () => true,
      inheritDesktop: true,
    },
  });

export default {
  ...timetableSettingsParams,
  ...dailyAgendaSettingsParams,
};
