import React from 'react';
import SectionHeader from '../../SectionHeader/SectionHeader';
import TimezoneSelection from '../../TimezoneSelection/TimezoneSelection';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
import TimeSlotsNotification from '../../TimeSlotsNotification/TimeSlotsNotification';
import WeekPicker from '../WeekPicker/WeekPicker';
import WeekAvailability, {
  DayWithoutSlotsIndicationFactory,
  DayWithSlotsFactory,
} from '../WeekAvailability/WeekAvailability';
import { WeeklyTimeSlotsLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import { SlotsStatus } from '../../../../../types/types';
import { classes } from './WeeklyTimeSlotsLayout.st.css';
import { DataHooks } from './constants';
import { TimeSlotsSelectionViewModel } from '../../../ViewModel/timeSlotsSelectionViewModel/timeSlotsSelectionViewModel';
import { TimeSlotsSelectionDisplayMode } from '../../TimeSlotsSelection/constants';
import TimeSlotsSelection from '../../TimeSlotsSelection/TimeSlotsSelection';

export interface WeeklyTimeSlotsLayoutProps {
  viewModel: WeeklyTimeSlotsLayoutViewModel;
  errorNotificationText: string;
}

const WeeklyTimeSlotsLayout: React.FC<WeeklyTimeSlotsLayoutProps> = ({
  viewModel: {
    bodyTitle,
    slotsStatus,
    timeSlotsNotificationViewModel,
    timezoneSelectionViewModel,
    noAvailableSlotsViewModel,
    weekPickerViewModel,
    slotsPerDays,
  },
  errorNotificationText,
}) => {
  const dayWithSlotsFactory: DayWithSlotsFactory<
    TimeSlotsSelectionViewModel
  > = ({ slotsViewModel, date }) =>
    slotsViewModel.timeSlots.length ? (
      <TimeSlotsSelection
        date={date}
        viewModel={slotsViewModel}
        displayMode={TimeSlotsSelectionDisplayMode.Column}
      />
    ) : null;

  const dayWithoutSlotsIndicationFactory: DayWithoutSlotsIndicationFactory<
    TimeSlotsSelectionViewModel
  > = ({ slotsViewModel, text }) =>
    slotsStatus === SlotsStatus.AVAILABLE_SLOTS &&
    !slotsViewModel.timeSlots.length
      ? text
      : null;

  return (
    <div className={classes.root} data-hook={DataHooks.WeeklyTimeSlotsLayout}>
      <SectionHeader
        title={bodyTitle}
        suffix={
          timezoneSelectionViewModel ? (
            <TimezoneSelection
              viewModel={timezoneSelectionViewModel}
              dataHook={DataHooks.Timezone}
            />
          ) : undefined
        }
      />
      <ErrorNotification errorText={errorNotificationText} />
      <WeekPicker viewModel={weekPickerViewModel!} />
      {slotsStatus === SlotsStatus.AVAILABLE_SLOTS ? (
        <TimeSlotsNotification viewModel={timeSlotsNotificationViewModel} />
      ) : null}
      <WeekAvailability
        slotsPerDays={slotsPerDays!}
        slotsStatus={slotsStatus}
        noAvailableSlotsViewModel={noAvailableSlotsViewModel!}
        dayWithSlotsFactory={dayWithSlotsFactory}
        dayWithoutSlotsIndicationFactory={dayWithoutSlotsIndicationFactory}
      />
    </div>
  );
};

export default WeeklyTimeSlotsLayout;
