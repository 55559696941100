import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import React from 'react';
import {
  ButtonSize,
  Button as TpaButton,
  ButtonProps as TpaButtonProps,
  ButtonPriority as TpaButtonPriority,
} from 'wix-ui-tpa/cssVars';
import { SpinnerWrapper } from '@wix/bookings-viewer-ui';
import stylesParams from '../../stylesParams';
import { classes, st } from './Button.st.css';
import { ButtonVariant, DataHooks } from './constants';

export interface ButtonProps extends Omit<TpaButtonProps, 'priority'> {
  variant?: ButtonVariant;
  loading?: boolean;
}

const mapVariantToPriority = {
  [ButtonVariant.FILLED]: TpaButtonPriority.primary,
  [ButtonVariant.OUTLINED]: TpaButtonPriority.secondary,
};

export const Button: React.FC<ButtonProps> = ({
  variant = ButtonVariant.FILLED,
  size = ButtonSize.medium,
  disabled,
  loading,
  children,
  wrapContent,
  className,
  ...restProps
}) => {
  const { isMobile } = useEnvironment();
  const styles = useStyles();
  const shouldWrapContent = wrapContent && !loading;

  const spinnerDiameter = isMobile
    ? styles.get(stylesParams.mobileBookButtonTextFontSize)
    : styles.get(stylesParams.bookButtonTextFont).size!;

  return (
    <TpaButton
      {...restProps}
      disabled={loading || disabled}
      wrapContent={shouldWrapContent}
      size={size}
      className={st(classes.root, { isMobile, variant }, className)}
      upgrade
      priority={mapVariantToPriority[variant]}
      data-variant={variant}
      data-size={size}
      data-wrapped={shouldWrapContent}
    >
      {loading ? (
        <div
          className={classes.spinnerWrapper}
          style={{ height: spinnerDiameter }}
        >
          <SpinnerWrapper
            className={classes.spinner}
            isCentered={true}
            diameter={spinnerDiameter}
            data-hook={DataHooks.SPINNER}
          />
        </div>
      ) : (
        children
      )}
    </TpaButton>
  );
};
