import React from 'react';
import { SummaryViewModel } from '../../ViewModel/summaryViewModel/summaryViewModel';
import { SummaryDataHooks } from './constants';
import { Text, TextButton } from 'wix-ui-tpa/cssVars';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { classes } from './Summary.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { TextButtonPriority } from 'wix-ui-tpa';
import { EditSmall as EditIcon } from '@wix/wix-ui-icons-common';

export interface SummaryProps {
  viewModel: SummaryViewModel;
}

export const Summary: React.FC<SummaryProps> = ({ viewModel }) => {
  const { openServicesPreferencesModal } = useCalendarActions();

  const {
    summary: { services, location, duration },
    openPreferencesButton,
  } = viewModel;

  return (
    <div data-hook={SummaryDataHooks.ROOT} className={classes.root}>
      <div className={classes.summary}>
        {!!services && (
          <Text
            tagName={AccessibilityHtmlTags.Paragraph}
            data-hook={SummaryDataHooks.SERVICES}
          >
            {services}
          </Text>
        )}

        {!!duration && (
          <Text
            data-hook={SummaryDataHooks.DURATION}
            aria-label={duration.durationAriaText}
            tagName={AccessibilityHtmlTags.Paragraph}
          >
            {duration.durationText}
          </Text>
        )}

        <Text
          data-hook={SummaryDataHooks.LOCATION}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {location}
        </Text>
      </div>

      <TextButton
        data-hook={SummaryDataHooks.OPEN_PREFERENCES_BUTTON}
        aria-label={openPreferencesButton.ariaLabel}
        onClick={() => openServicesPreferencesModal()}
        priority={TextButtonPriority.primary}
        className={classes.openPreferencesModalButton}
        prefixIcon={<EditIcon />}
      >
        {openPreferencesButton.text}
      </TextButton>
    </div>
  );
};
